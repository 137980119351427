﻿import { AddToCartResponse } from "@/interfaces"
import { handleAddToCartResponse } from "@/CartModule"
import { downloadFileFromBlob, get, getFileNameFromResponse, initForm } from "@/Utils"

function executePreview(response: Response) {
  const fileName = getFileNameFromResponse(response);
  return response.blob().then(blob => {
    if (blob.type === "text/html") {
      return blob.text().then(x => {
        get("gift-card-container").innerHTML = x;
        init();
      });
    }
    else {
        downloadFileFromBlob(blob, fileName);
        return null
    }
  });
}

function executeAddToCart(response: Response) {

  return response.json().then((x: AddToCartResponse) => {
    if (x.giftCard) {
      get("gift-card-container").innerHTML = x.giftCard;
      initValidators();
    }
    else {
      const btn = get("giftCardAddToCart");
      handleAddToCartResponse(btn, x);
    }
  });
}

function formPosted(response: Response) {
  if (response.url.endsWith("Preview")) {
    return executePreview(response);
  }
  else {
    return executeAddToCart(response);
  }
}

function setInputFilter(elem: HTMLInputElement, events, inputFilter) {
  events.forEach(event => {
    elem.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        const rangeMin = parseInt(this.getAttribute("data-val-range-min"));
        const rangeMax = parseInt(this.getAttribute("data-val-range-max"));
        let intValue = parseInt(this.value) || 0;
        intValue = intValue > rangeMax ? rangeMax : intValue;
        intValue = intValue < rangeMin ? rangeMin : intValue;
        this.value = this.value ? intValue.toString() : this.value;
        this.setAttribute("oldValue", intValue.toString());
        this.setAttribute("oldSelectionStart", this.selectionStart.toString());
        this.setAttribute("oldSelectionEnd", this.selectionEnd.toString());
      } else if (this.hasAttribute("oldValue")) {
        this.value = this.getAttribute("oldValue");
        this.setSelectionRange(parseInt(this.getAttribute("oldSelectionStart")), parseInt(this.getAttribute("oldSelectionEnd")));
      } else {
        this.value = "";
      }
    });
  });
}

function forceRange(elem: HTMLInputElement, events) {
  setInputFilter(elem, events, value => /^\d*$/.test(value));
}

function initValidators() {
  forceRange(get("Sum") as HTMLInputElement,
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"]);
}

function init() {
  initValidators();
}

window.addEventListener("load", () => {
  initForm("giftCard", formPosted);
  init();
});